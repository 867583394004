import {Image} from "semantic-ui-react";

const Footer = () => {

    return <div className="footer-container with-image">

        <Image src="/resources/images/267_Franchise_Freedom_©Ossip.jpg" style={{width: '100%'}} />

        <div className="footer">

            <div className="ui container">
                <h2>drone <span className="light">stories</span></h2>
                <p>
                    Amsterdam, The Netherlands<br/><br/>
                    Reach us at <a href="mailto:info@dronestories.show">info@dronestories.show</a><br/>
                    or <a href="tel:0031202442911">+31 20 244 2911</a>
                </p>

            </div>
        </div>

    </div>;

}

export default Footer;