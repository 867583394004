import Home from "./pages/Home";
import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import Shows from "./pages/Shows";
import YourStory from "./pages/your-story";
import Contact from "./pages/contact";
import Crew from "./pages/crew";
import ScrollToTop from "./components/ScrollToTop";
import FranchiseFreedom from "./pages/franchise-freedom";
import {useEffect, useState} from "react";
import CookieConsent, {Cookies, getCookieConsentValue} from "react-cookie-consent";
import {initGA} from "./ga-utils";

function App() {

    let [opacity, setOpacity] = useState(0);

    //https://stackoverflow.com/a/53090848/886283
    useEffect(
        () => {
            let timer1 = setTimeout(() => {
                setOpacity(1)
            }, 5 * 1000);

            // this will clear Timeout
            // when component unmount like in willComponentUnmount
            // and show will not change to true
            return () => {
                clearTimeout(timer1);
            };
        },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        []
    );

    const handleAcceptCookie = () => {
        initGA();
    };
    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);

    return <BrowserRouter>

        <ScrollToTop />

        <Route path={"/"} exact>
            <div className="header-video">
                <video autoPlay muted loop>
                    <source src="/resources/video/drone-stories-3-wide.mp4" type="video/mp4"/>
                </video>
                <div className='icon-scroll' id='icon-scroll' style={{opacity}} />
                <div className='logo-on-header-video'>
                    <img src="/resources/images/Drone Stories Logo White.png" />
                </div>
            </div>
        </Route>

        {/*<Navigation/>*/}

        <Switch>
            <Route path={"/"} exact component={Home}/>
            <Route path={"/our-work"} component={Shows}/>
            <Route path={"/your-story"} component={YourStory}/>
            <Route path={"/franchise-freedom"} component={FranchiseFreedom}/>
            <Route path={"/crew"} component={Crew}/>
            <Route path={"/contact"} component={Contact}/>
        </Switch>

        <br style={{clear: "both"}} />

        <Footer/>

        <CookieConsent enableDeclineButton
                       onAccept={handleAcceptCookie}
                       onDecline={handleDeclineCookie}>
            This website uses cookies to enhance the user experience.
        </CookieConsent>

    </BrowserRouter>;
}

export default App;
