import {Button, Container, Form, Grid, Message} from "semantic-ui-react";
import {useState} from "react";

const ContactForm = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const [error, setError] = useState("");

    const submit = (e) => {

        setError("");

        if (email === "" || phone === "") {
            setError("Please enter a phone number or an email address so we can contact you.")
            e.preventDefault();
            return false;
        }



    }

    return <>
        <Grid centered>
            <Grid.Row>
                <Grid.Column width={10} mobile={16} >

                    {error !== "" &&
                    <Message warning>
                        {error}
                    </Message>
                    }

                    <Form onSubmit={submit} method="POST" action="https://formspree.io/f/meqvejao">
                        <Form.Field>
                            <label>Name</label>
                            <input name="Name" placeholder='Name' value={name} onChange={e => setName(e.target.value)}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Email address</label>
                            <input name="E-mail" placeholder='Email address' value={email} onChange={e => setEmail(e.target.value)}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Phone number</label>
                            <input name="Phone" placeholder='Phone number' value={phone} onChange={e => setPhone(e.target.value)}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Message</label>
                            <Form.TextArea name="Message" placeholder='Message' value={message} onChange={e => setMessage(e.target.value)} />
                        </Form.Field>
                        <Button type='submit' basic>Send</Button>
                    </Form>

                </Grid.Column>
            </Grid.Row>
        </Grid>
    </>;
};

export default ContactForm;