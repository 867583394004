import {Container, Grid, Icon, Image} from "semantic-ui-react";
import {Parallax} from "react-parallax";
import React from "react";


const FranchiseFreedom = () => {
    return <>

        <Container>

            <h1><span className="light">Franchise</span> Freedom</h1>

            {/*<Grid>*/}
            {/*    <Grid.Row>*/}
            {/*        <Grid.Column width={6}>*/}

                        <p>
                            A flying sculpture by Studio Drift is a performative artwork at the intersection between technology,
                            science and art. An autonomous flying swarm of hundreds of drones, Franchise Freedom exposes the tension
                            between individual freedom and safety in numbers. Music composed and played by Joep Beving.
                        </p>
                        <p>
                            To create this artwork, Franchise Freedom, Drift studied the natural flight behavior of starlings and
                            translated this into software that has been specially developed and embedded in the drones.
                        </p>
                    {/*</Grid.Column>*/}

            {/*        <Grid.Column width={10}>*/}
            {/*        </Grid.Column>*/}
            {/*    </Grid.Row>*/}
            {/*</Grid>*/}


                    <Image src={"/resources/images/ff_rotterdam_Ossip_van_Duivenbode.jpg"}
                           style={{marginBottom: '20px'}}/>

            <p>
                The artwork translates into a poetic illustration of how we, as humans, strive to live autonomously
                within societies defined by rules and conventions. Although the patterns appear random, and the
                impression of such a swarm may remind us of freedom, the behavior of these birds is completely
                orchestrated and subject to many rules and survival instincts.
            </p>
            <p>
                There is a tremendous beauty in watching these sudden decisions of thousands of individuals and their
                reactions to one another. If every bird were to operate on its own, complete chaos would result. Just
                like birds, people find safety in a group, while at the same time they are forced to act according to a
                set of rules on which society functions. One who chooses complete individual freedom above these rules
                is forced to operate outside of society. What is the perfect balance between the two? Is freedom an
                illusion?
            </p>
            <p>
                Each performance is site-specific and unique, and the drones will react to their environment in an
                unpredictable, autonomous way. Their technology is based on ongoing university research on flocking
                behavior, as the principles of self-organization have become more and more relevant in our ever changing
                world.
            </p>
            <p>
                Franchise Freedom received the <a href="https://www.dezeen.com/awards/2019/winners/franchise-freedom-studio-drift/" target="_blank">Dezeen Lighting design of the year award</a> in 2019.
            </p>
            <p>
                <a href="https://www.studiodrift.com/franchise-freedom/" target="_blank">Read more on studiodrift.com <Icon disabled name='arrow right' /></a>
            </p>

        </Container>

        <Parallax bgImage="/resources/images/ff-al-ula.jpg"
                  bgImageAlt="Franchise Freedom - Amsterdam" strength={-200}>
            <div style={{height: '600px'}}/>
        </Parallax>

    </>;
}

export default FranchiseFreedom;