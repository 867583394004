import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const hash = window.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            // Check if there is a hash and if an element with that id exists
            document.getElementById(hash.substr(1)).scrollIntoView() //{behavior: "smooth"}
        } else {
            window.scrollTo(0, 0);
        }
    }, [pathname, window.location.hash])

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [pathname]);

    return null;
}