import {Container, Grid, Image} from "semantic-ui-react";
import {Link} from "react-router-dom";


const Crew = () => {
    return <>

        <Container>

            <h1><span className="light">meet our</span> crew</h1>

            <p>
                Drone Stories consists of a mix of experts that are constantly exploring new boundaries. With backgrounds in
                art, tech, and entertainment we are ready for every challenge.
                Our network of experts and creatives from all over the world will bring your story to the next level.
            </p>

            <br />

            <Grid>
                <Grid.Row centered>
                    <Grid.Column width={6}>
                        <Image src="../resources/images/DLL_01.jpg"/>
                    </Grid.Column>
                    <Grid.Column width={2}/>
                    <Grid.Column width={6}>
                        <Image src="../resources/images/DLL_testvlucht_eo_11.jpg"/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>


        </Container>

    </>;
}

export default Crew;