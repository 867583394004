import {Link} from "react-router-dom";
import ContactForm from "../components/Form";
import {Container, Grid} from "semantic-ui-react";
import {Waypoint} from 'react-waypoint';
import {useRef} from "react";

const Home = () => {

    const ffVideo = useRef(null);

    return <>
        <div className="ui container">

            <div>
                <h1>using the sky <span className="light">as our canvas</span></h1>
                <p>
                    We turn fiction into reality with our award-winning drone
                    performances and their next level designs.
                </p>

                <p>
                    Our shows have captivated people around the world. Whether you are looking for an intimate
                    performance or a grand spectacle, we leverage our international network of artists, musicians, animators and
                    producers to make your story come to life.
                </p>
                <p>
                    We take care of your project from A to Z. Our team has been successfully navigating challenging environments like remote desserts, major cities like LA and London, Kennedy Space Center and rocky islands.
                </p>

            </div>
            {/*</div>*/}

            {/*<Parallax bgImage="/resources/images/267_Franchise_Freedom_©Ossip.jpg"*/}
            {/*          bgImageAlt="Franchise Freedom - Amsterdam" strength={-100}>*/}
            {/*    <div style={{height: '400px'}}/>*/}
            {/*</Parallax>*/}

            {/*<div className="ui container">*/}

            <br />

            <strong>Drone Stories offers an all-inclusive package</strong>

            <ul>
                <li>Art direction, story telling, design and animation</li>
                <li>Fully operated drone fleet from 200 - 1000+ drones</li>
                <li>Permits to fly</li>
                <li>On-site production</li>
                <li>Professional film registration</li>
            </ul>

            <br/><br/>

            <div className="ui stackable relaxed grid">
                <div className="eight wide column">
                    <h3>our work</h3>

                    <div className="video-container-with-arrow">
                        {/*<Link to="/our-work">*/}
                            <video autoPlay muted loop>
                                <source src="../resources/video/drone-stories-3-wide.mp4" type="video/mp4"/>
                            </video>

                            {/*<img src="../resources/images/np_arrow_2332726_FFFFFF.png" className="pull-right arrow"/>*/}
                        {/*</Link>*/}

                    </div>
                </div>

                {/*<div className="one wide column"></div>*/}

                <div className="eight wide column">

                    <h3>our crew</h3>

                    <div className="video-container-with-arrow">
                        {/*<Link to="crew">*/}
                            <video autoPlay muted loop>
                                <source src="../resources/video/website_crew_v2.mp4" type="video/mp4"/>
                            </video>

                            {/*<img src="../resources/images/np_arrow_2332726_FFFFFF.png" className="pull-right arrow"/>*/}
                        {/*</Link>*/}
                    </div>

                </div>
            </div>
            {/*</div>*/}

            {/*<div className="ui container">*/}

            <br/><br/>

            <h2>let's create <span className="light">your story</span></h2>

            <p>
                In 2017 at Art Basel Miami we premiered with DRIFT’s artwork Franchise Freedom, the world’s first
                independently orchestrated drone performance resembling a murmuration of starlings. In collaboration
                with Duran Duran and DRIFT we created a show for the 50th anniversary of the landing on the moon. We
                also flew a magical 600 drone piece over the playa at Burning Man in 2018.
            </p>

            <p>
                Drone Stories was founded by <strong>DRIFT</strong>, <strong>PACE galleries</strong>,
                <strong>Superblue</strong> and <strong>Therme Art</strong> with strong roots in the
                global art, entertainment and tech community.
            </p>

        </div>

        <br/>
        <br/>
        <br/>
        <br/>

        <div className="big-video-container">
            <div className="ui stackable grid">
                <div className="twelve wide column video" style={{marginLeft: '20px'}}>
                    <div className="embed-container">
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/589281970?h=63dbf8bac1"
                            frameBorder="0" allowFullScreen/>
                    </div>
                    {/*<Waypoint*/}
                    {/*    bottomOffset={400}*/}
                    {/*    onEnter={() => {*/}
                    {/*        ffVideo.current.play()*/}
                    {/*    }}*/}
                    {/*    onLeave={() => {*/}
                    {/*        ffVideo.current.pause();*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <video muted loop ref={ffVideo}>*/}
                    {/*        <source src="../resources/video/website_FF_v3.mp4" type="video/mp4"/>*/}
                    {/*    </video>*/}
                    {/*</Waypoint>*/}
                </div>

                <div className="three wide column">
                    <div className="on-video-box" style={{marginTop: '120px'}}>
                        <h2>Franchise Freedom
                            {/*<span className="light">&mdash; NASA</span>*/}
                        </h2>
                        <p>
                            Franchise Freedom, as this piece is called, exposes the tension between individual freedom
                            and
                            safety in
                            numbers. Together with their unique movements, light plays an equally important role in
                            Franchise
                            Freedom and in any other drone light performance.
                        </p>
                        {/*<p>*/}
                        {/*    <Link to="/franchise-freedom" className="pull-right arrow">*/}
                        {/*        <img src="../resources/images/noun_Right%20Arrow_1136544.png"/>*/}
                        {/*    </Link>*/}
                        {/*</p>*/}
                        <br style={{clear: "both"}}/>
                    </div>
                </div>
            </div>

        </div>


        <Container className={"contactPage"}>

            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={8} textAlign={"center"}>

                        <div className="circle">
                            <img src="/resources/images/np_idea_2036256_FFFFFF.png"/>
                        </div>

                        <p>
                            Send us an e-mail at <a href="mailto:info@dronestories.show">info@dronestories.show</a><br/><br/>

                            or give Rebecca a call on <a href="tel:0031202442911">+31 20 244 2911</a>
                        </p>
                    </Grid.Column>

                    <Grid.Column width={8} textAlign={"center"}>

                        <div className="circle">
                            <img src="/resources/images/np_amsterdam_510843_FFFFFF.png"/>
                        </div>

                        <p>

                            In the neighbourhood? Drop by for a coffee!<br/><br/>

                            A'DAM Tower<br />
                            Overhoeksplein 1<br/>
                            1031 KS Amsterdam<br/>
                            The Netherlands

                        </p>

                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <br /><br />
            <h1><span className="light">follow us on Instagram</span> <a href="https://instagram.com/dronestories.show" target="_blank">@dronestories.show</a></h1>

            <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" className="crt-logo crt-tag">Powered by Curator.io</a></div>

            <br/>
            <h1><span className="light">let's have</span> a chat</h1>
            <ContactForm/>

        </Container>
    </>;
}

export default Home;