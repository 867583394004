import {Container, Grid, Image} from "semantic-ui-react";
import {Parallax} from "react-parallax";
import ContactForm from "../components/Form";

const Contact = () => {
    return <>

        <Container className={"contactPage"}>

            <h1><span className="light">let's have</span> a chat</h1>
            <p>
                Did we catch your attention and are you interested in our drone performance? Or would you simply just
                like to know what exactly is involved in such a process? Then you are in the right place. Please do not
                hesitate to reach out and find out!
            </p>

            <ContactForm />

            <br /><br />
            <br /><br />

            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={8} textAlign={"center"}>

                        <div className="circle">
                            <img src="/resources/images/np_idea_2036256_FFFFFF.png"/>
                        </div>

                        <p>
                            Send us an e-mail at <a href="mailto:info@dronestories.show">info@dronestories.show</a><br/><br/>

                            or give Rebecca a call on <a href="tel:0031202442911">+31 20 244 2911</a>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign={"center"}>

                        <div className="circle">
                            <img src="/resources/images/np_amsterdam_510843_FFFFFF.png"/>
                        </div>

                        <p>

                            In the neighbourhood? Drop by for a coffee!<br/><br/>
                            We're located in Amsterdam.<br/><br/>

                            Asterweg 20H<br/>
                            1031 HN Amsterdam<br/>
                            The Netherlands

                        </p>

                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Container>

        {/*<Parallax bgImage="/resources/images/267_Franchise_Freedom_©Ossip.jpg"*/}
        {/*          bgImageAlt="Franchise Freedom - Amsterdam" strength={-100}>*/}
        {/*    <div style={{height: '800px'}}/>*/}
        {/*</Parallax>*/}

    </>;
}

export default Contact;