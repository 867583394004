import {Container} from "semantic-ui-react";
import {Parallax} from "react-parallax";


const YourStory = () => {
    return <>

        <Container>

            <h1><span className="light">let's tell</span> a new kind of story</h1>

            <p>
                We would love to challenge you to think different about what's possible. Let your mind go free and
                create a story like never before.
            </p>

            <h2><span className={"light"}>this is</span> how we work</h2>
            <p>

                We don't believe we have all the answers &mdash; but we do know people who do. Our network of experts
                and creatives from all over the world will bring your story to the next level. Whether you want to fly a simple image,
                a 3D logo or a moving animation, we have the right animator for the job.

            </p>
            <p>
                For a lasting memory or to reach an even bigger audience we can capture the performance too. Our film makers and camera
                crews and drone operators have extensive experience in filming drone shows, from the ground and from the air.
            </p>

            <h2><span className={"light"}>simple</span> pricing</h2>
            <p>
                Our pricing is simple and transparent:
            </p>



        </Container>

        <Parallax bgImage="/resources/images/ff-al-ula.jpg"
                  bgImageAlt="Franchise Freedom - Al Ula" strength={-200}>
            <div style={{height: '900px'}}/>
        </Parallax>

    </>;
}

export default YourStory;