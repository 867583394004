import {Container, Icon} from "semantic-ui-react";
import {Waypoint} from "react-waypoint";
import {useRef} from "react";

const Shows = () => {

    const burningManVideo = useRef(null);
    const nasaVideo = useRef(null);
    const rotterdamVideo = useRef(null);
    const alUlaVideo = useRef(null);

    return <>
        <Container>

            <h1><span className={"light"}>this is</span> our work</h1>

            <p>
                We have captivated people around the world at the most stunning locations: from the desert of
                Nevada, United States, to the busy city centre of Amsterdam, The Netherlands.
            </p>

            <br/><br/>
        </Container>

        <div className="big-video-container">
            <div className="ui stackable grid mobile reversed">
                <div className="one wide column"/>
                <div className="three wide column">
                    <div className="on-video-box left">
                        <h2>Burning Man <span className="light"></span></h2>
                        <p>
                            As dusk fell over Black Rock City, 600 luminous drones rose into a hypnotic display of
                            technological choreography, accompanied by the poignant keys of Joep Beving.
                        </p>
                        <a href="https://vimeo.com/316556330" target="_blank">
                            Watch video <Icon disabled name='arrow right'/>
                        </a>
                        {/*<p>*/}
                        {/*    <a href="" className="pull-right arrow">*/}
                        {/*        <img src="../resources/images/noun_Right%20Arrow_1136544.png"/>*/}
                        {/*    </a>*/}
                        {/*</p>*/}
                        <br style={{clear: "both"}}/>
                    </div>
                </div>
                <div className="twelve wide column video">
                    <video autoPlay muted loop ref={burningManVideo}>
                        <source src="../resources/video/burning-man.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>

        </div>

        <br/><br/>
        <br/><br/>
        <br/><br/>

        <div className="big-video-container">
            <div className="ui stackable grid">
                <div className="twelve wide column video">
                    <Waypoint
                        bottomOffset={400}
                        onEnter={() => {
                            nasaVideo.current.play();
                            burningManVideo.current.pause();
                        }}
                        onLeave={({currentPosition}) => {
                            if (currentPosition === "below") {
                                burningManVideo.current.play();
                            }
                            nasaVideo.current.pause();
                        }}
                    >
                        <video ref={nasaVideo} muted loop>
                            <source src="../resources/video/website_nasa_v2.mp4" type="video/mp4"/>
                        </video>
                    </Waypoint>
                </div>
                <div className="three wide column">
                    <div className="on-video-box">
                        <h2>NASA<span className="light"></span></h2>
                        <p>
                            Duran Duran composed a piece of music specially for the artwork. As a tribute to
                            technological innovation and human evolution, Franchise Freedom is the first artwork to use
                            the sky as its canvas.
                        </p>
                        <p>
                            <a href="https://vimeo.com/356403807" target="_blank">
                                Watch video <Icon disabled name='arrow right'/>
                            </a>
                        </p>
                        <p>
                            <a href="/franchise-freedom" target="_blank">
                                About Franchise Freedom <Icon disabled name='arrow right'/>
                            </a>
                        </p>
                        {/*<p>*/}
                        {/*    <a href="" className="pull-right arrow">*/}
                        {/*        <img src="../resources/images/noun_Right%20Arrow_1136544.png"/>*/}
                        {/*    </a>*/}
                        {/*</p>*/}
                        {/*<br style={{clear: "both"}}/>*/}
                    </div>
                </div>
            </div>

        </div>

        <br/><br/>
        <br/><br/>
        <br/><br/>

        <div className="big-video-container">
            <div className="ui stackable grid mobile reversed">
                <div className="one wide column"/>
                <div className="three wide column">
                    <div className="on-video-box left">
                        <h2>
                            Rotterdam
                            {/*<span className="light">&mdash; city center</span>*/}
                        </h2>
                        <p>
                            Despite covid restrictions and the challenges of flying in a busy city center we flew,
                            amongst others,
                            an enormous beating heart over the Maas river.
                        </p>
                        <p>
                            Together with live musical performance, dancers and FPV (first-person view) drone
                            pilots we created an immersive visual experience with a strong message of hope.
                        </p>
                        <a href="https://youtu.be/FxQGS6Jpuyo" target="_blank">
                            Watch video <Icon disabled name='arrow right'/>
                        </a>
                        {/*<p>*/}
                        {/*    <a href="" className="pull-right arrow">*/}
                        {/*        <img src="../resources/images/noun_Right%20Arrow_1136544.png"/>*/}
                        {/*    </a>*/}
                        {/*</p>*/}
                        <br style={{clear: "both"}}/>
                    </div>
                </div>
                <div className="twelve wide column video">
                    <Waypoint
                        bottomOffset={400}
                        onEnter={() => {
                            rotterdamVideo.current.play();
                            nasaVideo.current.pause();
                        }}
                        onLeave={({currentPosition}) => {
                            if (currentPosition === "below") {
                                nasaVideo.current.play();
                            }
                            rotterdamVideo.current.pause();
                        }}
                    >
                        <video ref={rotterdamVideo} muted loop>
                            <source src="../resources/video/rotterdam-nye.mp4" type="video/mp4"/>
                        </video>
                    </Waypoint>
                </div>
            </div>

        </div>

        <br/><br/>
        <br/><br/>
        <br/><br/>

        <div className="big-video-container">
            <div className="ui stackable grid">
                <div className="twelve wide column video">
                    <Waypoint
                        bottomOffset={400}
                        onEnter={() => {
                            alUlaVideo.current.play()
                            rotterdamVideo.current.pause();
                        }}
                        onLeave={({currentPosition}) => {
                            if (currentPosition === "below") {
                                rotterdamVideo.current.play();
                            }
                            alUlaVideo.current.pause();
                        }}
                    >
                        <video ref={alUlaVideo} muted loop>
                            <source src="../resources/video/website_al_ula_v2.mp4" type="video/mp4"/>
                        </video>
                    </Waypoint>
                </div>
                <div className="three wide column">
                    <div className="on-video-box">
                        <h2>Al Ula</h2>
                        <p>
                            A magical experience in a magical place: the archaeological site and
                            new cultural hotspot of Al-Hijr in Al Ula, Saudi Arabia.
                        </p>
                        <p>
                            Franchise Freedom highlights the delicate balance and harmony between humans and their
                            environment, encouraging a healthy and global social dialogue that questions social
                            constructs and conceptions of freedom.
                        </p>
                        <a href="https://vimeo.com/415545171" target="_blank">
                            Watch video <Icon disabled name='arrow right'/>
                        </a>
                        <br style={{clear: "both"}}/>
                    </div>
                </div>
            </div>

        </div>


        <br/><br/>
        <br/><br/>
        <br/><br/>

        {/*
                Partners:
                BMW, Art Basel,
            */}

        {/*</Container>*/}

    </>;
}

export default Shows;